/* This overrides all tables */
table {
  width: 100%;
  color: black;
  overflow: auto;
  padding-top: 6px;
  margin-left: 16px;
  thead tr th {
    border-bottom: 3px solid #f2f2f2;
    border-collapse: separate;
    border-spacing: 0px 0px;
    color: #000;
    text-align: left;
  }
  tbody {
    tr {
      margin: 0px;
      padding: 0px;
    }
    tr:nth-child(2n) {
      background-color: #f2f2f2;
    }
    td {
      font-size: 16px;
      font-weight: normal;
      padding: 2px 0px;
      margin: 0px;
      color: black;
    }
  }
}
.table-alternate {
  td:nth-child(1) {
    width: 33%;
  }
}

.bg-hero-image {
  background: linear-gradient(rgba(30, 59, 109, 0.8), rgba(30, 59, 109, 0.8)), url(https://www.solutionview.com/wp-content/uploads/2020/06/Industrys-1920x616-web.jpg);
  background-size: cover;
}

.cta-button {
  border-radius: 4px;
  font-size: 1em;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
  }
}

.primary-cta {
  background-color: #6accf3;
  border: 1px solid #6accf3;
  color: #fff;
  &:hover {
    background-color: #fff;
    border: 1px solid #fff;
    color: #6accf3;
  }
}
.secondary-cta {
  background-color: #1e3b6d;
  border: 1px solid #1e3b6d;
  color: #fff;
  &:hover {
    background-color: #fff;
    border: 1px solid #1e3b6d;
    color: #1e3b6d;
  }
  &:disabled {
    background-color: #d7d7d7;
    border: 1px solid #d7d7d7;
    color: #828282;
    &:hover {
      cursor: default;
    }
  }
}

.phoneInput {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.overflow-touch {
  flex: 1 1;
}

/* MUI overrides */
button.MuiButton-root {
  //border-radius: 0px !important;
  text-transform: none !important;
}
button.MuiButton-containedPrimary {
  background-color: #1e3b6d !important;
}
button.MuiButton-containedSecondary {
  background-color: #6accf3 !important;
  margin-bottom: 10px;
}

button.MuiButton-containedPrimary:disabled {
  color: #dcdbdc !important;
}

.MuiFilledInput-root {
  background: rgb(232, 241, 250);
}

.MuiDialog-paper {
  padding: 15px;
}
.MuiLinearProgress-root {
  height: 12px !important;
  background-color: #f2f2f2 !important;
}
.MuiLinearProgress-colorPrimary {
  background-color: #1e3b6d !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #6accf3 !important;
}

/* Stripe stuff */
.StripeElement {
  display: block;
  color: black;
  margin: 0px;
  padding: 18px;
  font-size: 1em;
  border: 1px solid #c4c4c4;
  outline: 0px;
  border-radius: 4px;
  background: #ffffff;
}
